import React, { useEffect } from 'react';

import "./DrawingTool.css";

import Canvas from './Canvas';
import DrawingNavBar from './DrawingNavBar';
import scaleCanvas from '../../Utilities/scaleCanvas';


// To prevent it from being removed

function DrawingTool() {
  useEffect(() => {
    const handleWindowResize = () => {
      scaleCanvas();
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <>
      <div className="flex w-full h-full bg-abbott-black justify-center overflow-hidden touch-none">
        <div className="">
          <DrawingNavBar/>
          <div className="justify-center">
            <Canvas />
          </div>
        </div>
      </div>
    </>
  );
}
export default DrawingTool;
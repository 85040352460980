import './App.css';
import DrawingTool from './Components/DrawingTool/DrawingTool';

function App() {
  if(!window.itemDelimiter){
    window.itemDelimiter="!!item-deleimiter!!";
  }
  if(!window.canvasSize){
    window.canvasSize=800;
  }
  if(!window.ratio){
    window.ratio=1;
  }
  if(!window.canvasData){
    window.canvasData="|auto,Arial,left,auto|"; // this is a string for a card: "TEXT|FONT,FONT_SIZE,TEXT_ALIGN,FONT_COLOR|x1,y1,x2,y2,x3,y3|x1,y1,x2,y2,x3,y3" 
  }
  return (
    <div className="App">
      <DrawingTool />
    </div>
  );
}
export default App;